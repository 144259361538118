import { Link } from "gatsby";
import React from "react";
import PropTypes from "prop-types";
import { trackGAEvents } from "../common/trackGA";
import "./makesuccess.scss";
const MakeSuccess = ({ title, btnText, btnLink, externalLink, category, borderRadiusClass }) => {
  return (
    <div id="hasura-help" className="commonGrayBgColor clearBoth">
      <div className="containerWrapper">
        <div className="successWrapper clearBoth">
          <h2 className="articleTitle">{title}</h2>
          <div className="buttonWrapper">
            {externalLink ? (
              <a
                href={btnLink}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => trackGAEvents(category, "Button Click", btnText)}
              >
                <button className="commonBtn commonDarkBlueBtn">{btnText}</button>
              </a>
            ) : (
              <Link to={btnLink} onClick={() => trackGAEvents(category, "Button Click", btnText)}>
                <button className="commonBtn commonDarkBlueBtn">{btnText}</button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
MakeSuccess.propTypes = {
  title: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  btnLink: PropTypes.string.isRequired,
  externalLink: PropTypes.bool,
  category: PropTypes.string,
};
export default MakeSuccess;
