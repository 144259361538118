import React from "react";
// import { Link } from "gatsby"
import "./styles.scss";
const TopBanner = () => {
  return (
    <section className="commonSectionWrapper blueBgColor proTopBannerBG">
      <div className="containerWrapper">
        <div className="topBannerWrapperPartners">
          <div className="articleSubTitleHead fontNormal">Introducing the Hasura Dev Agency</div>
          <h1 className="title">Partnership Program</h1>
          <div className="articleDesc">
            The Hasura Dev Agency Partnership Program is a program for development agencies that use
            Hasura to work closely with the core Hasura team, and benefit from participation in
            Hasura’s partner marketing programs, free use of Hasura Pro and training & support from
            the core Hasura team.
          </div>
          <div className="buttonWrapper">
            <a href="https://forms.gle/xERHDyGpqr4rfRtSA" target="_blank" rel="noopener noreferrer">
              <button className="commonBtn greenGradientBtn">Apply Now</button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBanner;
