import React from "react";
// import { Link } from "gatsby"
import "./styles.scss";
import marketingPrograms from "./images/marketing-programs.svg";
import training from "./images/training.svg";
import hasuraPro from "./images/hasura-pro.svg";
import consulting from "./images/consulting.svg";
import referrals from "./images/referrals.svg";
import prioritySupport from "./images/priority-support.svg";
const featuresState = [
  {
    title: "Marketing programs",
    description:
      "Participation in Hasura’s partner marketing programs online (blog, website, social media) and offline (events, conferences).",
    imgSrc: marketingPrograms,
  },
  {
    title: "Training",
    description: "Workshops & training material for engineering and sales & marketing teams.",
    imgSrc: training,
  },
  {
    title: "Hasura Pro",
    description: "Free use of Hasura Pro for development.",
    imgSrc: hasuraPro,
    imgWd: true,
  },
  {
    title: "Consulting",
    description: "One on one consulting sessions with the core Hasura team.",
    imgSrc: consulting,
  },
  {
    title: "Referrals",
    description: "Referrals from application development queries that Hasura receives.",
    imgSrc: referrals,
  },
  {
    title: "Priority support",
    description: "Priority support during software development projects.",
    imgSrc: prioritySupport,
  },
];
const PartnershipProgram = () => {
  const featuresList = featuresState.map((list, index) => {
    return (
      <div key={index} className="col-md-6 col-sm-6 col-xs-12 flexContainer">
        <div className="partnershipFeaturesContainer flexList">
          <div className="partnershipFeaturesList flexAlign">
            <div className="partnershipFeaturesListImg">
              <img className={list.imgWd ? "smallImg" : ""} src={list.imgSrc} alt={list.title} />
            </div>
            <div className="partnershipFeaturesContent">
              <h3 className="articleSubTitleHead">{list.title}</h3>
              <div className="articleDesc">{list.description}</div>
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <section className="commonSectionWrapper">
      <div className="containerWrapper">
        <div className="partnershipProgramWrapper">
          <h2 className="articleTitle">Partnership Program Benefits</h2>
          <div className="partnershipFeaturesWrapper flexBoxWrapper">{featuresList}</div>
        </div>
      </div>
    </section>
  );
};

export default PartnershipProgram;
