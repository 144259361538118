import React from "react";
// import { Link } from "gatsby"
import HasuraUsers from "../userstories/hasurausers";
import "./styles.scss";
import curiousIt from "./images/curious-it.svg";
import curiousItBg from "./images/curious-it-bg.jpg";
import result from "./images/result.png";
import resultBg from "./images/result-bg.jpg";
import antstack from "./images/antstack.svg";
import antstackBg from "./images/antstack-bg.jpg";
import trigo from "./images/trigo.png";
import trigoBg from "./images/trigo-bg.jpg";
const FeaturedPartners = () => {
  return (
    <section>
      <div className="containerWrapper">
        <div className="featuredPartnersWrapper">
          <h2 className="articleTitle">Featured Partners</h2>
          <HasuraUsers
            brand={curiousIt}
            bgImage={curiousItBg}
            brandAlt="Curious IT"
            bgAlt="Background"
            title="WEB DEVELOPMENT"
            description={
              <div className="articleDesc">
                Curious IT
                <span className="fontNormal">
                  {" "}
                  specialize in writing highly robust middleware and integrations for corporate and
                  enterprise systems. These days, their focus is very much around modern tech -
                  static sites where possible, SPA or other hybrid approaches if more advanced
                  functionality needed; Headless or decoupled CMS; MVVM tech (React or Vue.JS) for
                  front end. They have a philosophical leaning towards highly modular system design
                  - i.e. integrating best of breed SaaS products to achieve flexible systems while
                  minimising vendor lock in and technical debt.
                </span>
              </div>
            }
            arrowLink="CHECK OUT THEIR WEBSITE"
            blogLink="https://curiousit.com/"
            textLeft
            id="curious-it"
            designation={
              <span>
                <span className="fontNormal">LOCATION – </span>AUSTRALIA
              </span>
            }
          />
          <HasuraUsers
            brand={result}
            bgImage={resultBg}
            brandAlt="Result"
            bgAlt="Background"
            title="WEB DEVELOPMENT"
            description={
              <div className="articleDesc">
                RESULT
                <span className="fontNormal">
                  {" "}
                  is helping global multinational companies to build information systems that
                  support faster growth and accurate decision making. RESULT is combining business
                  intelligence, process intelligence and artificial intelligence to accelerate
                  efficiency in the areas of management, business planning, sales, human resources,
                  supply chains, production and support.
                </span>
              </div>
            }
            arrowLink="CHECK OUT THEIR WEBSITE"
            blogLink="https://www.result.eu/graphql/"
            id="result"
            designation={
              <span>
                <span className="fontNormal">LOCATION – </span>SLOVENIA
              </span>
            }
          />
          <HasuraUsers
            brand={antstack}
            bgImage={antstackBg}
            brandAlt="Antstack"
            bgAlt="Background"
            title="WEB DEVELOPMENT"
            description={
              <div className="articleDesc">
                AntStack
                <span className="fontNormal">
                  {" "}
                  is a boutique consulting and development firm which focuses on Serverless, React,
                  GraphQL, and other relevant technology stacks. From UX to final product, they have
                  got everything covered.
                </span>
              </div>
            }
            arrowLink="CHECK OUT THEIR WEBSITE"
            blogLink="https://antstack.io/"
            textLeft
            id="antstack"
            designation={
              <span>
                <span className="fontNormal">LOCATION – </span>BANGALORE
              </span>
            }
          />
          <HasuraUsers
            brand={trigo}
            bgImage={trigoBg}
            brandAlt="TRIGO"
            bgAlt="Background"
            title="WEB DEVELOPMENT"
            description={
              <div className="articleDesc">
                TRIGO
                <span className="fontNormal">
                  {" "}
                  builds remarkable software solutions and the infrastructure it runs on. The team
                  of software engineers specializes in building enterprise-grade, data-heavy web
                  applications with a strong UX focus. Providing solutions for clients that are
                  reliable, scalable, and efficient. TRIGO prefers to work on visionary projects
                  that impact the work of software users and clients.
                </span>
              </div>
            }
            arrowLink="CHECK OUT THEIR WEBSITE"
            blogLink="https://trigodev.com/"
            id="trigo"
            designation={
              <span>
                <span className="fontNormal">LOCATION – </span>AUSTRIA
              </span>
            }
          />
        </div>
      </div>
    </section>
  );
};

export default FeaturedPartners;
