import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import TopBanner from "../components/partneragencies/topbanner";
import PartnershipProgram from "../components/partneragencies/partnershipprogram";
import FeaturedPartners from "../components/partneragencies/featuredpartners";
import MakeSuccess from "../components/userstories/makesuccess";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/partnership-program.png",
};
const canonicalUrl = "https://hasura.io/partner-agencies/";
const Careers = props => (
  <Layout location={props.location}>
    <Seo
      title="The Hasura Dev Agency Partnership Program"
      description="A partner program for Development Agencies that use Hasura to collaborate closely with the core Hasura team."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner />
    <PartnershipProgram />
    <FeaturedPartners />
    <MakeSuccess
      title="If you are a Development Agency that uses Hasura, we would love to partner with you."
      btnText="Apply Now"
      category="Partner Agencies page"
      btnLink="https://forms.gle/xERHDyGpqr4rfRtSA"
      externalLink
    />
  </Layout>
);

export default Careers;
