import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
const HasuraUsers = ({
  bgImage,
  brand,
  brandAlt,
  bgAlt,
  title,
  description,
  arrowLink,
  blogLink,
  textLeft,
  name,
  designation,
  overlayContent,
  waterMark,
  id,
  nameOne,
  designationOne,
}) => {
  return (
    <section id={id} className="addSectionPaddBottom">
      <div className="containerWrapper">
        <div className={"hasuraUserWrapper boxWrapper " + (waterMark ? " positionRel" : "")}>
          {waterMark ? (
            <img className="waterMarkFeatures" src={waterMark} alt="Water mark" />
          ) : null}
          <div
            className={
              textLeft
                ? "col-md-6 col-sm-6 col-xs-12 noPadd"
                : "col-md-6 col-sm-6 col-xs-12 col-md-push-6 noPadd"
            }
          >
            <div className="userImg">
              <img src={bgImage} alt={bgAlt} />
              {overlayContent ? <div className="articleTitle">{overlayContent}</div> : null}
            </div>
          </div>

          <div
            className={
              textLeft
                ? "col-md-6 col-sm-6 col-xs-12 noPadd"
                : "col-md-6 col-sm-6 col-xs-12 col-md-pull-6 noPadd"
            }
          >
            <div className="brandUsers">
              <img src={brand} alt={brandAlt} />
            </div>
            <div className="tag">{title}</div>
            <div className="greenSmallLine"></div>
            {description}
            {name ? <div className="articleSubTitle name">{name}</div> : ""}
            {designation ? (
              <div
                className={
                  "articleDescSmall " + (nameOne ? "designationPaddSmall" : "designationPadd")
                }
              >
                {designation}
              </div>
            ) : (
              ""
            )}
            {nameOne && designationOne ? (
              <div>
                <div className="articleSubTitle name">{nameOne}</div>
                <div className="articleDescSmall designationPadd">{designationOne}</div>
              </div>
            ) : (
              ""
            )}
            {arrowLink ? (
              <div className="commonLink">
                <a href={blogLink} target={"_blank"} rel="noopener noreferrer">
                  {arrowLink} <div className="arrow">→</div>
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
HasuraUsers.propTypes = {
  bgImage: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  brandAlt: PropTypes.string.isRequired,
  bgAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.element.isRequired,
  arrowLink: PropTypes.string,
  blogLink: PropTypes.string,
  textLeft: PropTypes.bool,
  name: PropTypes.string,
  designation: PropTypes.element,
  overlayContent: PropTypes.element,
  waterMark: PropTypes.string,
  id: PropTypes.string,
  nameOne: PropTypes.string,
  designationOne: PropTypes.element,
};
export default HasuraUsers;
